<template>
  <div class="screenPage">
    <div class="real_box">
      <div class="screen_title"><span class="liner_bgc">潼渚智慧党建云平台</span></div>
      <div class="screenBox flex_row">
        <div class="screen_left">
          <div class="screen_left_top">
            <div class="sec_title flex_row bgNew">组织架构</div>
            <div class="framework_box">
              <div class="one_line flex_row">
                <div class="card_item flex_row">
                  <img class="card_head" :src="`${oneFloorData.icon}`" />
                  <div class="card_mess">
                    <div class="card_position">党总支书记</div>
                    <div class="card_name">{{ oneFloorData.dyxm }}</div>
                  </div>
                </div>
              </div>
              <img class="connect_line" src="~@/assets/partyBuildingScreen/line_one.png" />
              <div class="two_line flex_row">
                <div class="card_item flex_row" v-for="(item, index) in twoFloorData" :key="index">
                  <div class="head_img"><img class="card_head" :src="`${item.icon}`" /></div>
                  <div class="card_mess">
                    <div class="card_position">副书记</div>
                    <div class="card_name">{{ item.dyxm }}</div>
                  </div>
                </div>
              </div>
              <img class="connect_line" src="~@/assets/partyBuildingScreen/line_two.png" />
              <div class="three_line flex_row">
                <div class="card_item" v-for="(item, index) in threeFloorData" :key="index">
                  <div class="head_img"><img class="card_head" :src="`${item.icon}`" /></div>
                  <div class="card_mess">
                    <div class="card_position">{{ weiyuan[index] }}</div>
                    <div class="card_name">{{ item.dyxm }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="screen_left_bottom">
            <div class="sec_title flex_row">荣誉墙</div>
            <div class="fameWall_box">
              <div class="fameWall_list flex_row">
                <el-image class="fameWall_img" :src="item.url" :preview-src-list="fameWallDataImages"
                  :initial-index="index" v-for="(item, index) in fameWallData" :key="index"></el-image>
              </div>
            </div>
          </div>
        </div>
        <div class="screen_center">
          <img src="~@/assets/partyBuildingScreen/centercircle.png" class="center_top_bgc circle" />
          <div class="center_top_bgc">
            <div class="center_top">
              <!-- 标题 -->
              <div class="center_top_title flex_row">
                <img src="~@/assets/partyBuildingScreen/titleicon_center.png" />
                <div class="liner_bgc">潼渚村党支部</div>
              </div>
              <!-- 图例 -->
              <div class="center_top_lengend" v-if="imageData">
                <div class="lengend_item flex_row">
                  <img src="~@/assets/partyBuildingScreen/lengend1.png" />
                  <div>
                    <div>正式党员</div>
                    <div><span style="font-size: 22px;">{{ imageData.dylxtj['1'] }}</span>人</div>
                  </div>
                </div>
                <div class="lengend_item flex_row">
                  <img src="~@/assets/partyBuildingScreen/lengend2.png" />
                  <div>
                    <div>预备党员</div>
                    <div><span style="font-size: 22px;">{{ imageData.dylxtj['0'] }}</span>人</div>
                  </div>
                </div>
              </div>

              <!-- 数值定位 -->
              <div class="pos1 pos flex_row">
                <img src="~@/assets/partyBuildingScreen/lengend1.png" />
                <div>
                  <div class="name_sec">冯道圩网格</div>
                  <div class="num_sec">党员:<span class="liner_bgc" style="font-size: 16px;">{{ imageData.wgtj[6]
                    }}</span>人</div>
                </div>
              </div>
              <div class="pos2 pos flex_row">
                <img src="~@/assets/partyBuildingScreen/lengend1.png" />
                <div>
                  <div class="name_sec">八仕网格</div>
                  <div class="num_sec">党员:<span class="liner_bgc" style="font-size: 16px;">{{ imageData.wgtj[5]
                    }}</span>人</div>
                </div>
              </div>
              <div class="pos3 pos flex_row">
                <img src="~@/assets/partyBuildingScreen/lengend1.png" />
                <div>
                  <div class="name_sec">桥西网格</div>
                  <div class="num_sec">党员:<span class="liner_bgc" style="font-size: 16px;">{{ imageData.wgtj[1]
                    }}</span>人</div>
                </div>
              </div>
              <div class="pos4 pos flex_row">
                <img src="~@/assets/partyBuildingScreen/lengend1.png" />
                <div>
                  <div class="name_sec">桥东1网格</div>
                  <div class="num_sec">党员:<span class="liner_bgc" style="font-size: 16px;">{{ imageData.wgtj[3]
                    }}</span>人</div>
                </div>
              </div>
              <div class="pos5 pos flex_row">
                <img src="~@/assets/partyBuildingScreen/lengend1.png" />
                <div>
                  <div class="name_sec">桥东2网格</div>
                  <div class="num_sec">党员:<span class="liner_bgc" style="font-size: 16px;">{{ imageData.wgtj[4]
                    }}</span>人</div>
                </div>
              </div>
            </div>
          </div>

          <div class="center_bottom">
            <div class="sec_title sec_title_long flex_row">党组织</div>
            <div class="organ_box">
              <div class="organ_list flex_row">
                <div class="organ_item" v-for="item in organData" :key="item.id">
                  <div class="organ_item_head flex_row">
                    <img class="head_icon" src="~@/assets/partyBuildingScreen/icon.png" />
                    <div style="margin-top: 10px">
                      <span class="num">{{ item.dyNum }}</span>人
                    </div>
                  </div>
                  <div class="partName">{{ item.zzmc }}</div>
                  <div class="organ_item_mess flex_row">
                    <i class="el-icon-s-custom" style="font-size: 18px"></i>
                    <div class="organ_item_label">负 责 人：</div>
                    <div class="organ_item_val">{{ item.fzr }}</div>
                  </div>
                  <div class="organ_item_mess flex_row">
                    <i class="el-icon-phone" style="font-size: 18px"></i>
                    <div class="organ_item_label">联系方式：</div>
                    <div class="organ_item_val">{{ item.tel }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="screen_right">
          <div class="screen_right_top">
            <div class="sec_title flex_row bgNew">
              <div>党员信息</div>
              <div class="sec_title_right liner_bgc">总数:<span style="margin: 0 5px;font-size: 22px;">{{ tableData.length
                  }}</span>人</div>
            </div>
            <div class="table_box">
              <div class="table_list">
                <el-table v-loading="loading" :data="tableData" :max-height="298" ref="scroll_Table" class="table_own"
                  :row-class-name="tableRowClassName" @mouseenter.native="autoScroll(true)"
                  @mouseleave.native="autoScroll(false)">
                  <el-table-column label="序号" type="index" width="50"></el-table-column>
                  <el-table-column prop="dyxm" label="姓名" width='70'></el-table-column>
                  <el-table-column prop="dzzName" label="所属支部" width='170'></el-table-column>
                  <el-table-column prop="rdrq" label="入党日期">
                    <template slot-scope="scope">
                      {{ scope.row.rdrq?scope.row.rdrq.slice(0, 10):"" }}
                    </template>
                  </el-table-column>
<!--                  <el-table-column prop="tel" label="联系方式" width='120'> </el-table-column>-->
                </el-table>
              </div>
            </div>
          </div>
          <div class="screen_right_center">
            <div class="sec_title flex_row">党建活动</div>
            <div class="carousel_box">
              <el-carousel trigger="click" :loop="true">
                <el-carousel-item v-for="(item, index) in carouselData" :key="item.id">
                  <div class="flex_row carousel_item">
                    <el-image class="carousel_item_img" :src="item.url" :preview-src-list="carouselDataImages"
                      :initial-index="index"></el-image>
                    
                    <div class="carousel_item_mess">
                      <div class="carousel_item_title">{{ item.title }}</div>
                      <div class="carousel_item_date">{{ item.releaseDate }}</div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
          <div class="screen_right_bottom">
            <div class="sec_title flex_row">党员风采</div>
            <div class="show_box">
              <el-carousel trigger="click" indicator-position="none">
                <el-carousel-item v-for="(item, index) in showData" :key="index">
                  <div class="flex_row show_item" v-for="(m, mid) in item" :key="m.id">
                    <el-image class="show_item_img" :src="m.url" :preview-src-list="showDataImages[index]"
                      :initial-index="mid"></el-image>

                    <div class="show_item_remark">{{ m.dyxm }}</div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div>
      </div>
      <div class="back_btn" @click="toIndex()">返回首页</div>
      <!-- <div class="bottom_line"></div> -->
    </div>
  </div>
</template>
<script>
export default {
  name: "partyBuildingScreen",
  components: {},
  data() {
    return {
      params: {
        page: 0,
        size: 10,
      },
      tableData: [], // 党员信息
      weiyuan:["纪律委员","宣传委员","组织委员"],//委员名称
      loading: false,

      scrolltimer: "", // 自动滚动的定时任务

      oneFloorData: {}, // 组织架构1级
      twoFloorData: [], // 组织架构2级
      threeFloorData: [], // 组织架构3级
      fameWallData: [], // 荣誉墙
      fameWallDataImages: [], // 荣誉墙的照片
      organData: [], // 党组织
      carouselData: [], // 党建活动
      carouselDataImages: [], // 党建活动的图片
      showData: [], // 党员风采
      showDataImages: [], // 党员风采图片
      imageData: [], // 中间大图的数据
    };
  },
  mounted() {
    this.token = JSON.parse(window.localStorage.getItem("token"));

    this.getMess();
  },
  beforeDestroy() {
    this.autoScroll(true);
  },

  methods: {
    toIndex() {
      this.$router.push(window.localStorage.getItem('screenRouter'));
    },
    // 获取数据
    getMess() {
      // 1. 组织架构  == 党员管理-（党员信息-分页查询 )
      this.$get("/dygl/page", { ...this.params, position: 0 }).then((res) => {
        if (res.data.state == "success") {
          // //console.log(res.data.data, "组织架构1级");
          this.oneFloorData =
            res.data.data.length > 0 ? res.data.data[0] : {};
        } else {
          this.oneFloorData = {};
        }
      });
      this.$get("/dygl/page", { ...this.params, position: 1 }).then((res) => {
        if (res.data.state == "success") {
          // //console.log(res.data.data, "组织架构2级");
          this.twoFloorData = res.data.data || [];
        } else {
          this.twoFloorData = [];
        }
      });
      this.$get("/dygl/page", { ...this.params, position: 2 }).then((res) => {
        if (res.data.state == "success") {
          // //console.log(res.data.data, "组织架构3级");
          this.threeFloorData = res.data.data || [];
        } else {
          this.threeFloorData = [];
        }
      });
      // 2. 荣誉墙  == 荣誉分页10个 == 荣誉管理-分页查询
      this.$get("/rongyugl/page", this.params).then((res) => {
        if (res.data.state == "success") {
          // //console.log(res.data.datas, "荣誉墙");
          let data = res.data.datas
          this.fameWallData = data.length > 6 ? data.slice(0, 6) : data;
          let imgs = []
          this.fameWallData.forEach(m => {
            imgs.push(m.url)
          })

          this.fameWallDataImages = imgs
        } else {
          this.fameWallData = [];
          this.fameWallDataImages = [];
        }
      });
      // 3.党组织  == 党组织3个 == 党组织管理-党组织列表接口
      this.$get("/dzzgl/list", {}).then((res) => {
        let organAllData
        if (res.data.state == "success") {
          // //console.log(res.data.data, "党组织");
          let a = [...res.data.data]
          organAllData = res.data.data
          this.organData = a.splice(0, 3).reverse();
        } else {
          this.organData = [];
        }

        // 4.党员信息  == 党员分页取10个  == 党员管理-（党员信息-党员列表
        this.$get("/dygl/list", {}).then((re) => {
          if (re.data.state == "success") {
            // //console.log(re.data.data, "党员信息", organAllData);
            let dyxx = re.data.data
            dyxx.forEach(item => {
              let arr = organAllData.filter(o => o.id == item.dzzId)
         
              if (arr.length > 0) {
                item.dzzName = arr[0].zzmc
              }
            })
            this.tableData = dyxx;
            setTimeout(() => {
              this.$nextTick(() => {
                this.autoScroll();
              })
            }, 2000)
          } else {
            this.tableData = [];
          }
        });
      });

      // 5.党建活动  == 活动分页10个  == 活动咨询-分页查询
      this.$get("/news/page", this.params).then((res) => {
        if (res.data.state == "success") {
          // //console.log(res.data.datas, "党建活动");
          this.carouselData = res.data.datas;
          let cimgs = [];
          this.carouselData.forEach(list => {
            cimgs.push(list.url)
          })
    
          this.carouselDataImages = cimgs;
        } else {
          this.carouselData = [];
          this.carouselDataImages = [];
        }
      });
      // 6.党员风采  == 党员管理 -（优秀党员-列表）
      this.$get("/dygl/listYxdy", {}).then(async (res) => {
        if (res.data.state == "success") {
          // //console.log(res.data.data, "党员风采");
          // this.showData = res.data.data;
          let resD = res.data.data;
          // 处理返回数据的格式，如果长度是偶数的话就/2,奇数的话就取余+1
          this.showData = this.group(
            resD,
            resD.length % 2 == 0 ? resD.length / 2 : (resD.length % 2) + 1
          );
          // //console.log(this.showData, "党员风采==处理后")
          let simgs = [];
          this.showData.forEach(list => {
            let os = []
            list.forEach(n => {
              os.push(n.url)
            })
            simgs.push(os)
          })
          this.showDataImages = simgs;
        } else {
          this.showData = [];
          this.showDataImages = []
        }
      });
      // 7.中间大图的数据
      this.$get("/dptj/dyxxtj", {}).then((res) => {
        if (res.data.state == "success") {
          // //console.log(res.data.data, "中间大图的数据");
          this.imageData = res.data.data;
        } else {
          this.imageData = {};
        }
      });
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return "warning-row";
      } else if (rowIndex % 2 === 0) {
        return "success-row";
      }
      return "";
    },

    // 设置自动滚动
    autoScroll(stop) {
      const table = this.$refs.scroll_Table;
      // 拿到表格中承载数据的div元素
      const divData = table.$refs.bodyWrapper;
      // 拿到元素后，对元素进行定时增加距离顶部距离，实现滚动效果(此配置为每100毫秒移动1像素)
      if (stop) {
        //再通过事件监听，监听到 组件销毁 后，再执行关闭计时器。
        window.clearInterval(this.scrolltimer);
      } else {
        this.scrolltimer = window.setInterval(() => {
          // 元素自增距离顶部1像素
          divData.scrollTop += 1;
          // 判断元素是否滚动到底部(可视高度+距离顶部=整个高度)
          if (
            divData.clientHeight + divData.scrollTop ==
            divData.scrollHeight
          ) {
            // 重置table距离顶部距离
            divData.scrollTop = 0;
            // 重置table距离顶部距离。值=(滚动到底部时，距离顶部的大小) - 整个高度/2
            // divData.scrollTop = divData.scrollTop - divData.scrollHeight / 2
          }
        }, 150); // 滚动速度
      }
    },

    // 将数组处理成嵌套数组的形式
    group(array, subGroupLength) {    
      let index = 0;
      let newArray = [];
      while (index < array.length) {
        newArray.push(array.slice(index, (index += subGroupLength)));
      }
      return newArray;
    },
  },
};
</script>

<style lang="less" scoped>
.flex_row {
  display: flex;
  align-items: center;
}

.bgNew {
  background-image: url("~@/assets/partyBuildingScreen/titlebgc.png") !important;

}

.sec_title {
  color: #FFEEEA;
  font-size: 24px;
  width: 100%;
  font-weight: bold;
  letter-spacing: 4px;
  background-image: url("~@/assets/partyBuildingScreen/titlebgc.gif");
  background-size: 100% 100%;
  justify-content: space-between;
  height: 3.3vw;
  align-items: flex-end !important;
  padding-bottom: 1.5%;
  padding-left: 4vw;
  box-sizing: border-box;

  .sec_title_right {
    padding-right: 25px;
    box-sizing: border-box;
    font-size: 17px;
    letter-spacing: 1px;
  }
}

.sec_title_long {
  background-image: url("~@/assets/partyBuildingScreen/littlebgctitle.gif");


}

.liner_bgc {
  /* 设置文本的背景为线性渐变 */
  background: linear-gradient(to bottom, #FFFFFF, #efa563);
  /* 使用 blend-mode 实现文本颜色与背景渐变颜色的混合 */
  -webkit-background-clip: text;
  background-clip: text;
  /* 使用 color 作为遮罩，实际看到的是背景渐变 */
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  /* 防止文本阴影遮挡背景渐变 */
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}

.screenPage::-webkit-scrollbar {
  display: none;
}

.screenPage {
  width: 100%;
  height: 100%;
  overflow: scroll;
  box-sizing: border-box;
  color: #efa563;

  .real_box {
    min-width: 1920px;
    min-height: 1080px;
    background-image: url("~@/assets/partyBuildingScreen/bgc.jpg");
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    position: relative;

    .screen_title {
      width: 100%;
      height: 8%;
      background-image: url("~@/assets/partyBuildingScreen/titlemainbgc.gif");
      background-size: 100% 107%;
      text-align: center;
      font-size: 40px;
      letter-spacing: 4px;
      font-style: italic;
      font-weight: bold;
      padding-top: 10px;
      box-sizing: border-box;
    }

    .bottom_line {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 8%;
      background-image: url("~@/assets/partyBuildingScreen/bottombgc.gif");
      background-size: 100% 100%;
    }

    .back_btn {
      position: absolute;
      right: 3.3%;
      top: 6%;
      color: #FFF3D2;
      border-radius: 20px;
      border: 1px solid #FFBFA2;
      letter-spacing: 2px;
      font-size: 17px;
      padding: 4px 13px;
      box-sizing: border-box;
      background: linear-gradient(to bottom, #B74C2C, #DD9119);
      cursor: pointer;

    }
  }

}

.screenBox {
  width: 100%;
  height: 88%;
  padding: 1% 3%;
  box-sizing: border-box;

  .screen_left {
    width: 27%;
    height: 100%;


    .screen_left_top {
      width: 100%;
      height: 70%;

      .framework_box {
        width: 100%;
        // margin-top: 1vw;
        height: calc(100% - 3.3vw);
        background-image: url("~@/assets/partyBuildingScreen/boxbgc.png");
        background-size: 100% 100%;
        padding: 40px 20px;
        box-sizing: border-box;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: space-around;

        .one_line {
          width: 100%;
          justify-content: center;

          .card_item {
            width: 220px;
            height: 110px;
            background-image: url("~@/assets/partyBuildingScreen/cardbgc.png");
            background-size: 100% 100%;

            .card_head {
              width: 70px;
              height: 70px;
              border-radius: 100%;
              overflow: hidden;
              margin: 1px 13px 0 13px;
            }

            .card_mess {
              display: flex;
              width: 110px;
              height: 100%;
              flex-flow: column;
              justify-content: center;
              margin-left: 10px;

              .card_position {
                margin-top: 5px;
                font-size: 16px;
                padding-bottom: 3px;
                box-sizing: border-box;
              }

              .card_name {
                font-size: 23px;
                letter-spacing: 5px;
                color:#fff;
              }
            }
          }
        }

        .connect_line {
          width: 78%;
        }

        .two_line {
          width: 100%;
          justify-content: space-around;

          .card_item {
            width: 180px;
            height: 90px;
            background-image: url("~@/assets/partyBuildingScreen/cardbgc.png");
            background-size: 100% 100%;

            .card_head {
              width: 60px;
              height: 60px;
              border-radius: 100%;

              overflow: hidden;
              margin: 5px 10px 0 10px;
            }

            .card_mess {
              display: flex;
              width: 110px;
              height: 100%;
              flex-flow: column;
              justify-content: center;
              margin-left: 10px;

              .card_position {
                margin-top: 5px;
                font-size: 15px;
                padding-bottom: 3px;
                box-sizing: border-box;
              }

              .card_name {
                font-size: 20px;
                letter-spacing: 5px;
                color:#FFF;
              }
            }
          }
        }

        .three_line {
          width: 100%;
          justify-content: space-between;
          padding: 0 10px;
          box-sizing: border-box;

          .card_item {
            display: flex;
            flex-flow: column;
            align-items: center;

            .head_img {
              width: 90px;
              height: 90px;
              background-image: url("~@/assets/partyBuildingScreen/headbgc.png");
              background-size: 100% 100%;

              .card_head {
                width: 60px;
                height: 60px;
                border-radius: 100%;

                overflow: hidden;
                margin: 15px 12px 0 15px;
              }
            }

            .card_mess {
              text-align: center;

              display: flex;
              width: 110px;
              height: 100%;
              flex-flow: column;
              justify-content: center;

              .card_position {
                margin-top: 5px;
                font-size: 15px;
                padding-bottom: 3px;
                box-sizing: border-box;
              }

              .card_name {
                font-size: 20px;
                letter-spacing: 5px;
                color:#fff;
              }
            }
          }
        }
      }
    }

    .screen_left_bottom {
      width: 100%;
      height: 30%;

      .fameWall_box {
        width: 100%;
        // margin-top: 1vw;
        height: calc(100% - 3.3vw);
        background-image: url("~@/assets/partyBuildingScreen/boxbgc.png");
        background-size: 100% 100%;
        padding: 20px;
        box-sizing: border-box;

        .fameWall_list {
          width: 100%;
          height: 100%;
          flex-wrap: wrap;
          align-items: flex-start;

          .fameWall_img {
            width: 32%;
            height: 48%;

            margin-right: 1%;
            // overflow: hidden;
          }
        }
      }
    }
  }



  .screen_center {
    width: 46%;
    height: 100%;
    position: relative;

    /* 定义关键帧 */
    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(-360deg);
      }
    }

    .circle {
      animation: rotate 15s linear infinite;
      position: absolute;
      left: 0;
      top: 0;
      height: auto !important;
      /*background-image: url("~@/assets/partyBuildingScreen/centercircle.png");
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: 0% 25%;*/

    }

    .center_top_bgc {
      width: 100%;
      height: 70%;

    }

    .center_top {
      width: 100%;
      height: 100%;
      position: relative;
      background-image: url("~@/assets/partyBuildingScreen/centerbgc.png");
      background-size: 100% 95%;
      background-position: bottom center;
      background-repeat: no-repeat;

      .center_top_title {
        position: absolute;
        top: 10%;
        right: 10%;
        font-weight: bold;
        font-size: 30px;
        letter-spacing: 2px;


        img {
          width: 50px;
          height: 50px;
          margin-right: 10px;
        }
      }

      .center_top_lengend {
        position: absolute;
        bottom: 5%;
        left: 5%;

        .lengend_item {
          font-size: 14px;
          font-weight: bold;
          margin-top: 10px;
          letter-spacing: 2px;

          img {
            width: 60px;
            height: 60px;
            margin-right: 10px;
          }
        }
      }

      .pos {
        color: #FFFFFF;

        img {
          width: 55px;
          height: 55px;
          margin-right: 10px;
        }

        .name_sec {
          font-weight: bold;
          font-size: 17px;
          letter-spacing: 1px;
        }

        .num_sec {
          margin-top: 3px;
          font-size: 13px;
          font-weight: bold;
        }
      }

      .pos1 {
        position: absolute;
        left: 9%;
        top: 14%;
      }

      .pos2 {
        position: absolute;
        left: 12%;
        top: 34%;
      }

      .pos3 {
        position: absolute;
        left: 31%;
        top: 65%;
      }

      .pos4 {
        position: absolute;
        left: 48%;
        bottom: 32%;
      }

      .pos5 {
        position: absolute;
        right: 12%;
        bottom: 35%;
      }
    }

    .center_bottom {
      width: 100%;
      height: 30%;


      .organ_box {
        width: 100%;
        height: calc(100% - 3.3vw);
        padding: 10px 20px 20px 20px;
        box-sizing: border-box;
        background-image: url("~@/assets/partyBuildingScreen/boxbgc.png");
        background-size: 100% 100%;

        .organ_list {
          width: 100%;
          height: 100%;
          padding: 10px 0 0 20px;
          box-sizing: border-box;

          .organ_item {
            width: 33%;
            height: 100%;
            margin-right: 2%;
            background-color: rgba(175, 4, 12, 0.5);
            border-radius: 10px;

            box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.2);
            padding: 5px 15px;
            box-sizing: border-box;

            .organ_item_head {
              font-size: 20px;
              justify-content: space-between;
              align-items: flex-start !important;

              .head_icon {
                width: 80px;
                height: 80px;
                margin-right: 10px;
                border-radius: 100%;
                overflow: hidden;
              }

              .num {
                font-size: 36px;
                font-weight: bold;
                margin-right: 3px;
              }
            }

            .partName {
              margin: 5px 0 5px 0;
              font-size: 18px;
              font-weight: bold;
              letter-spacing: 3px;
            }

            .organ_item_mess {
              margin-top: 5px;
              font-size: 15px;

              .organ_item_label {
                margin-left: 3px
              }

              .organ_item_val {
                color: #ffffff;
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
  }

  .screen_right {
    width: 27%;
    height: 100%;


    .screen_right_top {
      width: 100%;
      height: 44%;


      .table_box {
        width: 100%;
        height: calc(100% - 3.3vw);
        padding: 20px;
        box-sizing: border-box;
        background-image: url("~@/assets/partyBuildingScreen/boxbgc.png");
        background-size: 100% 100%;

        .table_list {
          width: 100%;
          height: 100%;

          .table_own {
            background-color: transparent !important;
          }
        }
      }
    }

    .screen_right_center {
      width: 100%;
      height: 26%;


      .carousel_box {
        width: 100%;
        height: calc(100% - 3.3vw);
        padding: 20px 30px;
        box-sizing: border-box;
        background-image: url("~@/assets/partyBuildingScreen/boxbgc.png");
        background-size: 100% 100%;

        .el-carousel {
          width: 100%;
          height: 100%;

          .el-carousel__container {
            height: 100%;
          }
        }

        .carousel_item {
          justify-content: space-between;
          align-items: flex-start;
          width: 100%;
          height: 100%;

          .carousel_item_img {
            width: 48%;
            height: 100%;
            border: 1px solid #efa563;
          }

          .carousel_item_mess {
            width: 49%;

            .carousel_item_title {
              font-weight: bold;
              font-size: 22px;
            }

            .carousel_item_date {
              color: #FFFFFF;
              margin-top: 8px;
              font-size: 15px;
            }
          }
        }
      }
    }

    .screen_right_bottom {
      width: 100%;
      height: 30%;


      .show_box {
        width: 100%;
        height: calc(100% - 3.3vw);
        padding: 20px;
        box-sizing: border-box;
        background-image: url("~@/assets/partyBuildingScreen/boxbgc.png");
        background-size: 100% 100%;

        .el-carousel {
          width: 100%;
          height: 100%;

          .el-carousel__container {
            height: 100%;
          }
        }

        .show_item {
          width: 49%;
          height: 100%;
          display: flex;
          flex-flow: column;
          align-items: center;
          justify-content: center;

          .show_item_img {
            width: 95%;
            max-height: 85%;
            border-radius: 10px;
          }

          .show_item_remark {
            width: 100%;
            text-align: center;
            font-size: 16px;
            margin-top: 5px;
            font-weight: bold;
          }
        }
      }
    }
  }
}

// .table_list ::v-deep .el-table tr {
//   background-color: transparent !important;
// }

.table_list ::v-deep .el-table .warning-row {
  background-color: #840B12;
}

.table_list ::v-deep .el-table .success-row {
  background-color: #AF0603;
}

.table_list ::v-deep .el-table .el-table__header-wrapper .el-table__header .has-gutter tr {
  background-color: transparent !important;
}

.table_list ::v-deep .el-table .el-table__header-wrapper .el-table__header .has-gutter tr .el-table__cell {
  background-color: transparent !important;
}

.table_list ::v-deep .el-table .el-table__header-wrapper .el-table__header .has-gutter tr .is-leaf {
  border-bottom: none !important;
}

.table_list ::v-deep .el-table thead {
  color: #F3976E !important;
  font-size: 16px !important;
}

.table_list ::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: none !important;
}

.table_list ::v-deep .el-table__body,
.el-table__footer,
.el-table__header {
  color: #FFFFFF !important;
}

.table_list ::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
  background-color: #6c060b !important;
  /* 修改为你想要的颜色 */
  // color: #606266; /* 修改为你想要的文本颜色 */
}

::v-deep .el-carousel__container {
  width: 100%;
  height: 100%;

  .el-carousel__item {
    width: 100%;
    height: 100%;
    display: flex;
  }

}

.carousel_box ::v-deep .el-carousel .el-carousel__indicators .el-carousel__indicator .el-carousel__button {
  width: 8px !important;
  height: 8px !important;
  border-radius: 100% !important;
  background-color: #620002 !important;
}

.carousel_box ::v-deep .el-carousel .el-carousel__indicators .is-active .el-carousel__button {
  width: 20px !important;
  height: 8px !important;
  border-radius: 20px !important;
  background-color: #FDB330 !important;
}

.carousel_box ::v-deep .el-carousel .el-carousel__indicators--horizontal {
  transform: none !important;
  right: 20px !important;
  left: none !important;
}
</style>